import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { TocItem } from "entities/toc-item";

function isElement(element: any) {
  return element instanceof Element || element instanceof HTMLDocument;
}

function getIds(items: any) {
  return items.reduce((acc: string[], item: TocItem) => {
    if (item?.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item?.url?.slice(1));
    }
    if (item?.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, []);
}

function useActiveId(itemIds: string[]) {
  const [activeId, setActiveId] = useState(``);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` }
    );

    itemIds.forEach((id) => {
      if (isElement(document.getElementById(id))) {
        observer.observe(document.getElementById(id) as HTMLElement);
      }
    });
    return () => {
      itemIds.forEach((id) => {
        if (isElement(document.getElementById(id))) {
          observer.unobserve(document.getElementById(id) as HTMLElement);
        }
      });
    };
  }, [itemIds]);
  return activeId;
}

function renderItems(items: TocItem[], activeId: string) {
  return (
    <Timeline
      sx={{
        p: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {items.map((item, index) => (
        <>
          <TimelineItem sx={{ margin: 0, padding: 0, minHeight: 0 }}>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  margin: 0,
                  background:
                    activeId === item?.url?.slice(1)
                      ? "rgba(242, 169, 0, 0.35)"
                      : "none",
                  boxShadow: "none",
                  border: "2px solid",
                  borderColor: "primary.main",
                }}
              />
              {/* {index < items.length - 1 && ( */}
              <TimelineConnector
                sx={{
                  border: "none",
                  borderLeft: "2px dashed",
                  borderColor: "divider",
                  background: "transparent",
                }}
              />
              {/* )} */}
            </TimelineSeparator>
            <TimelineContent
              sx={{
                p: 0,
                pl: 1,
                pb: 1,
                transform: "translateY(-8px)",
              }}
            >
              <Link
                href={item.url}
                sx={{
                  fontSize: 13,
                  textDecoration: "none",
                  color:
                    activeId === item?.url?.slice(1)
                      ? "primary.main"
                      : "text.primary",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                {item.title}
              </Link>

              {item.items && (
                <div style={{ marginTop: 15 }}>
                  {renderItems(item.items, activeId)}
                </div>
              )}
            </TimelineContent>
          </TimelineItem>
        </>
      ))}
    </Timeline>
  );
}

export default function TableOfContents(props: { items: TocItem[] }) {
  const idList = getIds(props.items);
  const activeId = useActiveId(idList);

  return (
    <>
      <h3>Table of Contents</h3>

      {renderItems(props.items, activeId)}
    </>
  );
}
